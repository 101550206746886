import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import TopNavBar from '../../components/TopNavBar'
import SideNavBar from '../../components/SideNavBar'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../stylesheets/sidenavbar.css'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Table from 'react-bootstrap/Table'
import { BsEye } from 'react-icons/bs';

class searchHistoryStudents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            students: []
        }
    }

    componentWillMount() {
    }

    searchHandler = e => {
        const value = e.target.value;

        if (value.length >= 2) {
            this.searchStudent(value)
        }
    }

    searchStudent(keyword) {
        fetch(process.env.REACT_APP_API_URL + "student/" + keyword + "/search/history",
            {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('access_token')
                }
            }).then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({students: data})
            })
            .catch(error => {
                console.log(error)
            });
    }

    render() {
        return <Container fluid>
            <Row>
                <Col style={{ margin: "0px", padding: "0px" }}>
                    <TopNavBar />
                </Col>
            </Row>
            <Row className="h-100">
                <Col xs={1.8}>
                    <SideNavBar className='sidebar' />
                </Col>

                <Col>
                    {/* Here comes the content of the page */}
                    <h1>Student zoeken in geschiedenis</h1>
                    <Row>
                        <Col></Col>
                        <Col xs={6}>
                            <Row>
                                <InputGroup size="sm" className="mt-2">
                                    <FormControl
                                        placeholder="Zoek student naam, klas"
                                        aria-label="text"
                                        aria-describedby="basic-addon2"
                                        onChange={this.searchHandler}
                                    />
                                    <InputGroup.Append style={{ backgroundColor: '#0064AD', }} >
                                        <InputGroup.Text id="basic-addon2" style={{ backgroundColor: '#0064AD', borderColor: '#0064AD', color: '#fff' }}>Zoek</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Row>

                            <Row>
                                <Table>
                                    <thead>
                                        <th>Studentnummer</th>
                                        <th>Studentnaam</th>
                                        <th>Slb'er</th>
                                        <th>Klas</th>
                                        <th>Bekijk</th>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.students.length <= 0 ? "Geen resultaten om weer te geven" : (
                                            this.state.students.map(student => {
                                                return (
                                                    <tr key={student.id}>
                                                        <td>{student.studentId}</td>
                                                        <td>{student.firstName} {student.insert} {student.lastName}</td>
                                                        <td>{student.classes[0] != null ? (student.classes.filter(c => c.active)[0].class.mentor) : "Niet bekend"}</td>
                                                        <td>{student.classes[0] != null ? (student.classes.filter(c => c.active)[0]?.class.name) : "Niet bekend"}</td>
                                                        <td><a href={"/student/" + student.id} className="addons-item"><BsEye /></a></td>
                                                    </tr>
                                                )
                                            }))
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </Col>
                        <Col></Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    }
}

export default searchHistoryStudents;